<template>
  <div class="box">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          :title="title"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
    </div>
    <div class="content contentbox loadingbox" v-if="loadingfalg">
      <van-loading size="24px" vertical>加载中...</van-loading>
    </div>
    <div class="content contentbox" v-if="showage && !loadingfalg">
      <Steps
        :levels="levels"
        v-if="levels && appStatus == 1"
        :hierarchy="hierarchy"
      />
      <div class="content-show">
        <van-tabs
          v-model="active"
          :animated="true"
          :class="appStatus == 1 ? 'disappear' : ''"
        >
          <van-tab title="详情页" name="a">
            <van-list :finished="true" finished-text="没有更多了">
              <van-collapse v-model="activeNames">
                <van-collapse-item title="重新询价信息" name="1" icon="label">
                  <van-cell-group class="group">
                    <van-cell title="申请名称" :value="details.topic" />
                    <van-cell title="申请原因" :value="details.reason" />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item title="备注与附件" name="2" icon="label">
                  <van-cell-group class="group">
                    <van-cell title="备注" :value="details.remark" />
                    <van-cell title="附件">
                      <div
                        v-for="(item, index) in details.fileList"
                        :key="index"
                        class="cell"
                      >
                        <a
                          v-if="item.suffix != 'rar' && item.suffix != 'zip'"
                          :href="
                            '/api-ec/file/FileuploadAction/' +
                              item.id +
                              '-' +
                              item.title
                          "
                          >{{ item.title }}</a
                        >
                        <span v-else @click="onCompression">{{
                          item.title
                        }}</span>
                      </div>
                    </van-cell>
                  </van-cell-group>
                </van-collapse-item>
              </van-collapse>
            </van-list>
          </van-tab>
          <van-tab title="审批记录" name="b" v-if="appStatus != 1">
            <div
              class="record"
              v-for="(item, index) in details.wfapproveinfoVOList"
              :key="index"
            >
              <van-cell-group>
                <van-cell title="审批层级" :value="item.node"></van-cell>
                <van-cell title="审批节点" :value="item.nodename"></van-cell>
                <van-cell title="审批人" :value="item.userName"></van-cell>
                <van-cell title="审批时间" :value="item.enddatetime"></van-cell>
                <van-cell title="审批结论" :value="item.resultType"></van-cell>
                <van-cell
                  title="审批意见"
                  :value="item.result"
                  v-if="item.resultType == '不同意'"
                ></van-cell>
              </van-cell-group>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <div
      class="footer"
      v-if="appStatus == 1 && showage == true && !loadingfalg"
    >
      <div class="footer-left" @click="agree" v-if="show">
        <span><van-icon name="success" size="20"/></span>
        <span>同意</span>
      </div>
      <div class="footer-right" @click="noagree" v-if="show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
      <div class="footer-left" v-if="!show">
        <span><van-icon name="success" size="20"/></span>
        <span>提交中</span>
      </div>
      <div class="footer-right" v-if="!show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
    </div>
    <Noagree v-if="!showage" :submitflag="submitflag" />
  </div>
</template>
<script>
import { Dialog } from "vant";
import { Toast } from "vant";
export default {
  data() {
    return {
      loadingfalg: true,
      submitflag: false,
      show: true,
      showage: true,
      title: "重新询价审批详情",
      loading: false,
      finished: true,
      isLoading: false,
      active: "a",
      activeNames: ["1", "2", "3", "4", "5", "6"],
      details: [],
      levels: null,
      hierarchy: 1,
    };
  },
  created() {
    this.getReEnquireDetail();
  },
  computed: {
    reEnquireId() {
      return this.$route.query.id;
    },
    appStatus() {
      return this.$route.query.appStatus;
    },
  },
  methods: {
    async getReEnquireDetail() {
      const params = {
        reEnquireId: this.reEnquireId,
      };
      let data = await this.$api.ec.getReEnquireDetail(params);
      if (data) {
        this.loadingfalg = false;
      }
      this.details = data;
      this.levels = Number(data.levels);
      if (this.details.fileList) {
        this.details.fileList.map((item) => {
          item.suffix = item.title.substring(item.title.lastIndexOf(".") + 1);
        });
      }
    },
    ondown(item) {
      let query = {
        id: item.id,
      };
      this.$router.push({ name: "preview", query });
    },
    async auditApply(o, message) {
      let params;
      if (o == 2) {
        this.submitflag = true;
        params = {
          reEnquireId: this.reEnquireId,
          audit: 0,
          leadexam: message,
        };
      } else {
        params = {
          reEnquireId: this.reEnquireId,
          audit: 1,
          leadexam: "同意",
        };
      }
      let data = await this.$api.ec.auditReEnquire(params);
      if (data) {
        Toast.success("提交成功");
        setTimeout(() => {
          this.$router.push({
            name: "ecmakeinquiry",
            query: { type: 1 },
          });
        }, 1000);
      }
    },
    onClickLeft() {
      if (this.showage) {
        this.$router.push({
          name: "ecmakeinquiry",
        });
      } else {
        this.showage = true;
        this.title = "重新询价审批详情";
      }
    },
    onCompression() {
      Dialog.alert({
        message: "压缩包请到电脑端查看",
      }).then(() => {
        // on close
      });
    },
    onLoad() {},
    listDetails() {},
    agree() {
      Dialog.confirm({
        title: "审批",
        message: "是否同意",
      })
        .then(() => {
          this.show = false;
          this.auditApply();
        })
        .catch(() => {
          // on cancel
        });
    },
    noagree() {
      this.title = "审批";
      this.showage = false;
    },
  },
};
</script>
<style lang="less" scoped>
.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #70e6ea;
}
.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f77269;
}
/deep/.group {
  .van-cell {
    color: #8f8f8f;
  }
}
/deep/.van-cell {
  background-color: transparent;
}
/deep/.van-cell {
  font-size: 12px;
}
/deep/.van-collapse-item__content {
  font-size: 12px;
  padding: 0;
}
/deep/.van-cell__value {
  color: #333;
}
.supplies {
  margin-top: 10px;
  min-height: 160px;
  background-color: #fff;
  padding: 0.3rem;
  border-radius: 9px;
  margin: 0.3rem;
  .supplies-title {
    font-weight: 400;
    line-height: 25px;
    font-size: 13px;
  }
  .supplies-text {
    color: #8f8f8f;
    line-height: 25px;
  }
}
.record {
  margin-top: 10px;
}
</style>
